(function () {
  'use strict';

  /* @ngdoc object
   * @name dashboard
   * @description
   *
   */
  angular
    .module('dashboard', [
      'ng-webcat',
      'app.firedux',
      'app.themes',
      'app.routes',
      'app.filters',
      'root'
    ]);
}());
