(function () {
  'use strict';

  angular
    .module('app.routes', [
      'ui.router'
    ])
    .config(config);

  function config($urlRouterProvider) {
    $urlRouterProvider.otherwise('/root');
  }
}());
