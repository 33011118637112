(function () {
  'use strict';
  angular
    .module('app.filters', [])
    .filter('chrono', function () {
      return function (time) {
        var ms = time % 1000,
            seconds = (time - ms) / 1000,
            secs = seconds % 60,
            minutes = (seconds - secs) / 60,
            mins = minutes % 60,
            hours = (minutes - mins) / 60,
            // hrs = hours % 6,
            // days = (hours - hrs) / 6,
            minsString = mins + 'm ',
            hrsString = hours + 'h ',
            // daysString = days + 'wd ',
            string =
              // (days > 0 ? daysString : '') +
              (hours > 0 ? hrsString : '') +
              (mins > 0 ? minsString : '');
        return string;
      };
    });
}());
