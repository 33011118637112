(function () {
  'use strict';
  class Controller {
    constructor($filter) {
      this.$filter = $filter;
    }
    $onChanges() {
      this.$setHits(this.projectHits);
    }
    $setHits(hits) {
      let previousTimestamps = {},
          entityTimes = {};
      this.$entities = [];
      angular.forEach(hits, hit => {
        /* eslint-disable */
        /* jshint ignore:start */
        hit.isWrite = hit.is_write;
        /* eslint-disable */
        /* jshint ignore:end */
        if (!previousTimestamps[hit.entity]) {
          previousTimestamps[hit.entity] = hit.timestamp;
          entityTimes[hit.entity] = 0;
        } else if (hit.timestamp - previousTimestamps[hit.entity] <= 10 * 60 * 1000 && !hit.isWrite) {
          entityTimes[hit.entity] += hit.timestamp - previousTimestamps[hit.entity];
        } else if (hit.isWrite) {
          entityTimes[hit.entity] += hit.timestamp - previousTimestamps[hit.entity];
        }
        previousTimestamps[hit.entity] = hit.timestamp;
      });
      angular.forEach(entityTimes, (time, entity) => {
        this.$entities.push({
          entity,
          filename: this.$entityFilename(entity),
          path: this.$entityPath(entity),
          time
        });
      });
    }
    $sumTimes(query, array) {
      let total = 0;
      angular.forEach(this.$filter('filter')(array, query), item => {
        total += item.time;
      });
      return total;
    }
    $entityPath(entity) {
      let array = this.entityArray(entity);
      array.pop();
      return array.join('/');
    }
    $entityFilename(entity) {
      return this.entityArray(entity).pop();
    }
    entityArray(entity) {
      return this.cleanEntity(entity).split('/');
    }
    cleanEntity(entity) {
      return entity.replace(
        entity.substr(
          0,
          entity.indexOf(
            '/dev/' + this.projectId + '/'
          ) + 6 + this.projectId.length
        ),
        ''
      );
    }
  }
  angular
    .module('root.detail', [])
    .component('rootDetail', {
      controller: Controller,
      templateUrl: 'root/detail/detail-root.html',
      bindings: {
        projectTime: '<',
        projectId: '<',
        projectHits: '<'
      }
    })
    .config(function ($stateProvider) {
      $stateProvider
        .state('root.detail', {
          abstract: false,
          url: '/detail/:projectId',
          template: ''
        });
    });
}());
