(function () {
  'use strict';
  function config($mdThemingProvider) {
    $mdThemingProvider.theme('default')
      .primaryPalette('blue', {
        default: '500'
      })
      .accentPalette('amber', {
        default: '500'
      });
    $mdThemingProvider.theme('white')
      .primaryPalette('grey', {
        default: '50'
      })
      .accentPalette('blue', {
        default: '500'
      })
      .backgroundPalette('grey', {
        default: '100'
      });
  }
  angular
    .module('app.themes', [])
    .config(config);
}());
