(function () {
  'use strict';
  angular
    .module('app.firedux', [])
    .run(run);
  function run($firedux) {
    var config = {
      apiKey: 'AIzaSyDEc5ANSFNlg2iNyYW7nfwhwMTaEEhepoM',
      authDomain: 'timetracker-ecd54.firebaseapp.com',
      databaseURL: 'https://timetracker-ecd54.firebaseio.com',
      storageBucket: 'timetracker-ecd54.appspot.com'
    };
    $firedux.init(config);
  }
}());
