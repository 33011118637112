(function () {
  'use strict';
  class Controller {
    constructor($state, $firedux, $timeout) {
      this.workMinutes = 10;
      this.idleMinutes = 30;
      this.$timeout = $timeout;
      this.$firedux = $firedux;
      this.$state = $state;
    }
    $onInit() {
      this.$changeTime(0.333);
      this.$firedux.val('time').watch(time => {
        this.$updateTimes(time);
      });
    }
    $setHits(hits) {
      let previousTimestamps = {},
          previousTimestamp,
          idleTime = 0,
          projectTimes = {};
      this.$projects = [];
      angular.forEach(hits, hit => {
        /* eslint-disable */
        /* jshint ignore:start */
        hit.isWrite = hit.is_write;
        /* eslint-disable */
        /* jshint ignore:end */
        if (!previousTimestamps[hit.project]) {
          previousTimestamps[hit.project] = hit.timestamp;
          previousTimestamp = hit.timestamp;
          projectTimes[hit.project] = 0;
        } else if (hit.timestamp - previousTimestamps[hit.project] <= this.workMinutes * 60 * 1000) {
          projectTimes[hit.project] += hit.timestamp - previousTimestamps[hit.project];
        } else if (
          hit.timestamp - previousTimestamps[hit.project] > this.workMinutes * 60 * 1000 &&
          hit.timestamp - previousTimestamps[hit.project] < this.idleMinutes * 60 * 1000
        ) {
          idleTime += hit.timestamp - previousTimestamp;
        } else if (hit.isWrite) {
          projectTimes[hit.project] += hit.timestamp - previousTimestamps[hit.project];
        }
        previousTimestamps[hit.project] = hit.timestamp;
        previousTimestamp = hit.timestamp;
      });
      angular.forEach(projectTimes, (time, projectId) => {
        this.$projects.push({
          projectId,
          time
        });
      });
      this.$idleTime = idleTime;
      this.$projectTimes = projectTimes;
    }
    $sumTimes(projects) {
      let total = 0;
      angular.forEach(projects, project => {
        total += project.time;
      });
      return total;
    }
    $getProjectHits(projectId, hits) {
      let projectHits = [];
      angular.forEach(hits, hit => {
        if (hit.project === projectId) {
          projectHits.push(hit);
        }
      });
      return projectHits;
    }
    $changeTime(time) {
      this.$firedux.val('time').set(time);
      this.$autoRefresh = time <= 1 ? true : false;
    }
    $updateTimes(time) {
      this.startTime = Date.now() - time * 24 * 3600 * 1000;
      this.$loaded = false;
    }
    $changeProject(projectId) {
      if (projectId) {
        this.$state.go('root.detail', {
          projectId
        });
        this.$loaded = false;
        this.$autoRefresh = false;
      } else {
        this.$state.go('root');
        this.$loaded = false;
        this.$autoRefresh = false;
      }
    }
  }
  angular
    .module('root', [
      'root.detail'
    ])
    .component('rootEl', {
      controller: Controller,
      templateUrl: 'root/root.html'
    })
    .config(function ($stateProvider) {
      $stateProvider
        .state('root', {
          abstract: false,
          url: '/root',
          template: '<root-el/>'
        });
    });
}());
